.c-stepper {
  /* display: flex;
    flex-wrap: wrap; */
  --size: 2rem;
  --spacing: 0.5rem;
  --customBlue: rgb(62, 142, 211);
  /* --step-background-color: rgb(117, 113, 117);
    --line-color: #e0e0e0; */
  padding: 1rem;
}

.c-stepper__item {
  /* display: flex;
    flex-direction: column;
    flex: 1; */
  text-align: center;
}

.c-stepper__item:before {
  content: attr(data-content);
  display: flex;
  width: var(--size);
  height: var(--size);
  border-radius: 15%;
  color: #e0e0e0;
  /* background-color: var(--step-background-color); */
  text-align: center;
  align-items: center;
  justify-content: center;
  margin: 0 auto 0.5rem;
}

.c-stepper__item[data-step-status="current"]:before {
  background-color: rgb(62, 142, 211);
}

.c-stepper__item[data-step-status="tbd"]:before {
  background-color: rgb(117, 113, 117);
}

.c-stepper__item[data-step-status="finished"]:before {
  background-color: #1da973;
}

.c-stepper__item:not(:last-child):after {
  content: "";
  position: relative;
  top: calc(var(--size) / 2);
  width: calc(100% - var(--size) - calc(var(--spacing) * 2));
  left: calc(50% + calc(var(--size) / 2 + var(--spacing)));
  height: 2px;
  /* background-color: var(--line-color); */
  background-color: attr(data-step-line-color);
  order: -1;
}

.c-stepper__item[data-step-status="finished"]:not(:last-child):after {
  background-color: #1da973;
}

.c-stepper__item[data-step-status="tbd"]:not(:last-child):after {
  background-color: #e0e0e0;
}

.c-stepper__item[data-step-status="current"]:not(:last-child):after {
  background-color: #e0e0e0;
}

.c-stepper__title {
  font-weight: bold;
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}

.c-stepper__desc {
  color: grey;
  font-size: 1rem;
  padding-left: var(--spacing);
  padding-right: var(--spacing);
}
