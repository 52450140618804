.slide-pane__header{
    flex: inherit;
    border: 0px !important;
    background: inherit !important;
    flex-direction: row-reverse;

}

.slide-pane__title{
    font-weight: bold !important;
    font-size: 1rem !important;
}

.slide-pane__close svg {
    width: 16px !important;
}

.slide-pane__close{
    margin-left: 0px !important;
    padding: 0px !important;
    padding-right: 26px !important;
}

.slide-pane{
    margin-top: 3.12rem;
    height: calc(100% - 3.12rem) !important;
    background: #F5F5F5 !important;
}

.slide-pane__content{
    padding-top: 0px !important;
}