.modal-minus-header {
  z-index: 101;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity*/
}

.body-content {
  background-color: #f5f5f5;
  height: 100%;
  overflow: auto;
  /* padding: 0px 1rem; */
}

.main-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.cam-font {
  font-family: "adobe-clean";
}

.stepper-box {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
