.body {
  /* font-family: "Adobe Clean"; */
  display: flex;
  flex-direction: column;
  /* height: 100vh; */
  /* overflow: hidden; */
  /* margin: 0;
  padding: 0; */
  /* overflow-anchor: none; */
}

.body > .searchBar {
  display: flex;
  margin-inline-start: 0px;
  border-radius: 5px;
  color: #4b4b4b;
  margin-left: 20px;
  width: 32%;
  color: black;
}

.body > .heading {
  color: #2c2c2c;
  margin-left: 25px;
  margin-right: 20px;
  margin-top: 25px;
}

.searchicon {
  margin-top: 25px;
  margin-left: 5px;
  margin-right: 5px;
}

.search-field {
  margin-top: 16px;
  color: #4b4b4b;
  width: 300px;
  border: 1px solid black;
  height: 60px;
  border-radius: 5px;
}

.AccountHierarchyTree {
  /* font-family: "Adobe Clean"; */
  /* display: flex;
  flex-direction: row; */
  /* height: 80vh; */
  /* height: 100%; */
  flex: 1;
  /* overflow: hidden; */
  /* margin: 0;
  padding: 0; */
  /* overflow-anchor: none; */
}

.propose-bottom {
  margin-left: 80%;
}

.alertbox-shown {
  margin-left: 10px;
  opacity: 1;
  transition: all 250ms ease;
  margin-top: 20px;
  width: 70%;
  margin-left: 10%;
  height: 35px;
  display: flex;
  justify-content: center;
  background-color: #bcbfec;
  border-radius: 15px;
  padding: 10px;
  font-family: "Adobe Clean";
  font-size: 25px;
  font-weight: bold;
}

.alertbox-hidden {
  display: none;
  opacity: 0;
  transition: all 250ms linear 3s;
}

.textbox-display {
  margin-top: 20px;
}

.alertbox-parent {
  /* width: 1400px; */
  text-align: center;
  justify-content: center;
}

.typehead {
  padding: 20px;
  color: #4b4b4b;
}

.accountHierarchy_node_modified .rst__rowContents {
  background-color: #587958;
  color: white;
}

.accountHierarchy_node .rst__moveHandle {
  background-color: #ffd800;
}

.accountHierarchy_node_modified .rst__moveHandle {
  background-color: #ffd800;
}

.cam_tree_node_child_modified {
  color: #008080;
}

.nodeSubtitle {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

.AHtooltip .right {
  width: max-content;
  top: 50%;
  left: 100%;
  margin-left: 1.25rem;
  transform: translate(0, -50%);
  padding: 0;
  color: #eeeeee;
  background-color: #444444;
  font-weight: normal;
  font-size: small;
  border-radius: 0.5rem;
  position: absolute;
  z-index: 99999999;
  box-sizing: border-box;
  box-shadow: 0 0.0625rem 0.5rem rgba(0, 0, 0, 0.5);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.8s;
}

.AHtooltip:hover .right {
  white-space: pre-line;
  width: max-content;
  max-width: 100%;
  visibility: visible;
  opacity: 1;
}

.AHtooltip .text-content {
  padding: 0.625rem 1.25rem;
}

.AHtooltip .right i {
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -12px;
  width: 0.75rem;
  height: 1.5rem;
  overflow: hidden;
}
.AHtooltip .right i::after {
  content: "";
  position: absolute;
  width: 0.75rem;
  height: 0.75rem;
  left: 0;
  top: 50%;
  transform: translate(50%, -50%) rotate(-45deg);
  background-color: #444444;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
}
