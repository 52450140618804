.card-body {
  background-color: white;
  transition: transform 200ms ease-in-out;
}
.card-body:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.custom-button {
  all: unset;
}
