.main-navigation {
  text-align: center;
  width: 60vw;
  justify-content: start;
  margin-left: 2rem;
}
.spectrum-Tabs {
  color: #101010;
  border-bottom-color: #101010;
}

.spectrum-Tabs-item {
  color: #989898;
}

.spectrum-Tabs-item.is-selected {
  color: #fff;
}
.spectrum-Tabs-selectionIndicator {
  color: #fff;
}
span.spectrum-Tabs-itemLabel > a {
  color: #fff;
  text-decoration: none;
}

.is-selected > .spectrum-Tabs-itemLabel {
  color: #fff;
  text-decoration: none;
}

span.spectrum-Tabs-itemLabel {
  color: #838383;
  text-decoration: none;
}

.header-container > .main-navigation > .spectrum-Tabs-selectionIndicator {
  background-color: #fff;
}
