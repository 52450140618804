.config {
  padding: 36px;
}

.config > .heading {
  color: #2c2c2c;
}

.config > .search-field {
  margin-top: 20px;
  color: #4b4b4b;
}

.config > .datagrid {
  margin-top: 30px;
  color: #2c2c2c;
}
