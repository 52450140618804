/* .step-card {
    border: 1px solid #333;
    border-radius: 0.5rem;
    padding: 0.5rem;
    background-color: white;
} */

.sample {
  display: flex;
  align-items: center;
  justify-content: center;
}
