.cam_tree_node .rst__moveHandle {
  background-color: #ffd800;
}

.cam_tree_node_modified .rst__rowContents {
  background-color: #8fbc8f;
  color: white;
}

.source_system_node .rst__rowContents {
  background-color: #cbdcff;
  color: black;
}

.cam_tree_node_selected .rst__rowContents {
  border: 3px solid #8fbc8f;
}

.tree {
  height: 35vmax;
  /* border: none; */
  width: 45rem;
  padding: 1rem;
  border-style: dashed;
}

.tree .rst__tree {
  height: 96% !important;
}

.cam_tree_node_child_modified {
  color: #008080;
}

.tooltip .right {
  width: max-content;
  top: 50%;
  left: 100%;
  margin-left: 1.25rem;
  transform: translate(0, -50%);
  padding: 0;
  color: #eeeeee;
  background-color: #444444;
  font-weight: normal;
  font-size: small;
  border-radius: 0.5rem;
  position: absolute;
  z-index: 99999999;
  box-sizing: border-box;
  box-shadow: 0 0.0625rem 0.5rem rgba(0, 0, 0, 0.5);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.8s;
}

.tooltip:hover .right {
  white-space: pre-line;
  width: max-content;
  max-width: 100%;
  visibility: visible;
  opacity: 1;
}

.tooltip .text-content {
  padding: 0.625rem 1.25rem;
}

.tooltip .right i {
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -12px;
  width: 0.75rem;
  height: 1.5rem;
  overflow: hidden;
}
.tooltip .right i::after {
  content: "";
  position: absolute;
  width: 0.75rem;
  height: 0.75rem;
  left: 0;
  top: 50%;
  transform: translate(50%, -50%) rotate(-45deg);
  background-color: #444444;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
}

.cam_tree_node_deleted .rst__rowContents {
  background-color: #880808;
  color: white;
}
