.logo-section {
  cursor: pointer;
  text-decoration: none;
}
.app-title {
  width: fit-content;
  margin-left: 10px;
  margin-top: 3px;
  margin-right: 25px;
}
