.user-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 1rem;
}

.icon-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  border: none;
  outline: none;
  border-radius: 50%;
  animation: bell 1s linear infinite alternate-reverse;
  transform-origin: top;
}

.icon-button__badge {
  position: absolute;
  top: -0.4rem;
  right: -0.7rem;
  width: 1rem;
  height: 1rem;
  background: red;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 0.65rem;
  border: 0.19rem solid #01111b;
}

@keyframes bell {
  0% {
    transform: rotate(-8deg);
  }
  100% {
    transform: rotate(8deg);
  }
}
