.Embed-dashboard {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0.6rem 10rem;
}

iframe {
  border: none;
}
