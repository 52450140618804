.header {
  background-color: #1e1e1e;
  color: #fff;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 100;
}

.header > .main-navigation {
  border-bottom: none;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  width: 100%;
  padding: 0 1rem;
}
